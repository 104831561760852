import React from "react";
import { Row, Col, Visible } from "react-grid-system";
import { Heading } from "../components";
import Page from "./Page";
import { BuySubscriptionButton } from "./BuySubscriptionButton";
import { connectComponent } from "../store";

const Tickets = ({
  subscriptionLink,
  startCurrentSeasonDate,
  endCurrentSeasonDate,
}) => {
  return (
    <Page>
      <Row>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Heading as="h1" style={{ marginBottom: "25px" }}>
            Biglietti
          </Heading>
          <p>
            Intero Spettacoli: <strong>15 &#8364;</strong>
            <br />
            Ridotto Under 25: <strong>12,50 &#8364;</strong>
            <br />
            Ridotto Under 12: <strong>7,50 &#8364;</strong>
            <br />
            Sotto i 2 anni: <strong>gratis</strong>
            <br />
            Ridotto Persona con Disabilità: <strong>10 &#8364;</strong>
            <br />
            Abbonamento 4 Venerdì: <strong>45 &#8364;</strong> (eventi{" "}
            <strong>EXTRA esclusi</strong>) <br />
            <strong>I posti sono numerati</strong>
            <br />
            Si bancomat / carte di credito.
            <br />
            <BuySubscriptionButton
              subscriptionLink={subscriptionLink}
              startCurrentSeasonDate={startCurrentSeasonDate?.value}
              endCurrentSeasonDate={endCurrentSeasonDate?.value}
            />
            <br />
            <br />
            Orario spettacoli: <strong>21:30</strong>
            <br />
            Orario eventi EXTRA: variabile in base all'evento.
            <br />
            Si consiglia di presentarsi al botteghino{" "}
            <strong>almeno 20 minuti prima</strong> {"dell'inizio."}
            <br />
            In caso di maltempo, l'evento si terrà presso il{" "}
            <a
              className="underlined"
              href={"https://goo.gl/maps/er3V8Xd83KsScQvv6"}
              target="_blank"
              rel="noopener noreferrer"
            >
              Palazzetto dello sport di Ricadi
              <i
                style={{ marginLeft: "5px" }}
                className="fa fa-external-link"
                aria-hidden="true"
              ></i>
            </a>
            <br />
            <br />
            Servizio primo soccorso a cura di Croce Azzurra Tropea e Ricadi.
          </p>
        </Col>
        <Col xs={12} sm={12} md={6} lg={6}>
          <Visible xs sm>
            <div style={{ marginTop: "25px" }}></div>
          </Visible>
          <Heading as="h1" style={{ marginBottom: "25px" }}>
            Prevendite e vendite online
          </Heading>
          <p>
            Evita la fila la sera dello spettacolo e compra online, su Whatsapp
            o per telefono!
            <br />
            <br />
            <strong>
              <a
                className="underlined"
                href={
                  "https://www.diyticket.it/festivals/598/avvistamenti-teatrali-2024"
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.diyticket.it/
                <i
                  style={{ marginLeft: "5px" }}
                  className="fa fa-external-link"
                  aria-hidden="true"
                ></i>
              </a>{" "}
              <br />
              oppure{" "}
              <a
                className="underlined"
                target="_blank"
                rel="noopener noreferrer"
                href={`https://wa.me/39060406?text=${encodeURIComponent(
                  "Buongiorno vorrei prenotare 2 biglietti per lo spettacolo di Avvistamenti al teatro torre marrana..."
                )}`}
              >
                Whatsapp
                <i
                  style={{ marginLeft: "5px" }}
                  className="fa fa-whatsapp"
                  aria-hidden="true"
                ></i>
              </a>
              <br /> o per telefono allo{" "}
              <strong>
                <a
                  className="underlined"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={"tel:+39060406"}
                >
                  06 04 06
                  <i
                    style={{ marginLeft: "5px" }}
                    className="fa fa-phone"
                    aria-hidden="true"
                  ></i>
                </a>
              </strong>
            </strong>
          </p>
        </Col>
      </Row>
    </Page>
  );
};

export default connectComponent(Tickets, function (state, ownProps) {
  return {
    subscriptionLink:
      state.items.seasons[state.items.selectedSeason].subscriptionLink ?? null,
    startCurrentSeasonDate:
      state.items.seasons[state.items.selectedSeason].startDate ?? null,
    endCurrentSeasonDate:
      state.items.seasons[state.items.selectedSeason].endDate ?? null,
  };
});

