export default {

    apiEndpoint: 'https://avvistamenti.prismic.io/api',

    // -- Access token if the Master is not open
    accessToken: 'MC5XWkhzc2lnQUFKMDNJOTdq.QzXvv707RQvvv73vv73vv71777-977-9X3NLB23vv73vv70L77-977-9Z1Tvv73vv70OYu-_vSgNOA',

    // OAuth
    clientId: 'WZHssigAANA4I97i',
    clientSecret: '0fe0d225f726e34a71de0a64aead3d45',

    // -- Links resolution rules
    // This function will be used to generate links to Prismic.io documents
    // As your project grows, you should update this function according to your routes
    linkResolver(doc, ctx) {
        return '/';
    },
};