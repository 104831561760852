import React from "react";
import { Button } from "../../components";

export const TwoTicketsBuyButton = ({ variant, children, ...rest }) => {
  return (
    <Button
      {...rest}
      variant={variant}
      style={variant === "secondary" ? { backgroundColor: "black" } : null}
    >
      {children}
      <img
        style={{ marginLeft: ".5rem" }}
        alt={"two tickets logo"}
        src="https://www.2tickets.it/img/logo.png"
        height={25}
      />
    </Button>
  );
};


export const DoItYourSelfBuyButton = ({ variant, children, ...rest }) => {
  return (
    <Button
      {...rest}
      variant={variant}
      style={{ backgroundColor: "black", color: "white" }}
    >
      {children}
      <img
        style={{ marginLeft: ".5rem" }}
        alt={"do it yourself tickets logo"}
        src="https://www.diyticket.it/images/diy_light.png"
        height={25}
      />
    </Button>
  );
};