export function createMarkup(html) {
  return { __html: html };
}

export function htmlToText(htmlString) {
  const el = document.createElement("div");
  el.innerHTML = htmlString;
  return el.textContent;
}

export function setPrerenderReady(bool, delay = 3000) {
  setTimeout(() => (window.prerenderReady = bool), delay);
}
