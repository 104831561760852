import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Hidden, Visible } from "react-grid-system";
import { Card, Loader, Image, Select, Heading, Label } from "../components";
import Page from "./Page";
import { connectComponent } from "../store";
import Chip from "../components/Chip";
import { BuySubscriptionButton } from "./BuySubscriptionButton";

/*[
  "https://www.2tickets.it/SelectTitolo.aspx?idt=1965862&set=T&car=&ide=2300",
  "https://www.2tickets.it/SelectTitolo.aspx?idt=1965863&set=T&car=&ide=2300",
  "https://www.2tickets.it/SelectTitolo.aspx?idt=1965892&set=&car=&ide=2300",
  "https://www.2tickets.it/SelectTitolo.aspx?idt=1965864&set=T&car=&ide=2300",
  "https://www.2tickets.it/SelectTitolo.aspx?idt=1965865&set=T&car=&ide=2300",
  "https://www.2tickets.it/SelectTitolo.aspx?idt=1965891&set=A&car=&ide=2300"
]*/

const ChipExtra = () => {
  return (
    <Chip color="white" bgColor="rgba(44,44,44)">
      Extra
    </Chip>
  );
};

const ChipMusic = () => {
  return (
    <Chip color="white" bgColor="rgba(44,44,44)">
      Music
    </Chip>
  );
};

const ChipTheater = () => {
  return (
    <Chip color="white" bgColor="rgba(44,44,44)">
      Teatro
    </Chip>
  );
};

const categoryToChip = {
  theater: <ChipTheater />,
  extra: <ChipExtra />,
  talk: null,
  music: <ChipMusic />,
  cinema: null,
};

class Shows extends Component {
  constructor(props) {
    super(props);
    this.buildRows = this.buildRows.bind(this);
    this.getShows = this.getShows.bind(this);
  }

  getShows(e) {
    if (e.target.value) {
      this.props.getShowsBy({ season: e.target.value });
    }
  }

  componentDidMount() {
    this.props.getShowsBy({ season: this.props.currentSeason });
  }

  buildRows() {
    const view = window.innerWidth <= 768 ? "mobile" : "tablet";
    return (
      <Row>
        {this.props.items.map((el) => {
          const title = el.data.item.title.value[0].text;
          const date = el.data.item.when.value;
          const category = el.data.item.type.value; // theater/music/extra/talk
          const imgSrc =
            el.data.item["image-gallery"].value[0].image.value.views[view].url;
          const { width, height } =
            el.data.item["image-gallery"].value[0].image.value.views[view]
              .dimensions;
          const adult = el.data.item.adult ? el.data.item.adult.value : 0;
          return (
            <Col xs={12} sm={12} md={6} lg={6} key={el.uid}>
              <Link to={`/shows/${el.uid}`}>
                <Card
                  date={date}
                  img={
                    <Image
                      alt={title}
                      src={imgSrc}
                      width={width}
                      height={height}
                    />
                  }
                  adult={adult}
                  category={categoryToChip[category]}
                />
              </Link>
            </Col>
          );
        })}
      </Row>
    );
  }

  render() {
    const { subscriptionLink, startCurrentSeasonDate, endCurrentSeasonDate } =
      this.props;
    return (
      <Page>
        <Row wrap="wrap">
          <Visible xs sm>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{ textAlign: "center", marginBottom: "1.35rem" }}
            >
              <BuySubscriptionButton
                subscriptionLink={subscriptionLink}
                startCurrentSeasonDate={startCurrentSeasonDate?.value}
                endCurrentSeasonDate={endCurrentSeasonDate?.value}
              />
            </Col>
          </Visible>
          <Col xs={12} sm={12} md={6} lg={6}>
            <Label id="seasons">
              <Heading as="h2" size="l">
                Stagione
              </Heading>
            </Label>

            <Select
              id="seasons"
              onChange={this.getShows}
              value={this.props.currentSeason}
            >
              {this.props.availableSeasons.map((season) => {
                return (
                  <option key={season} value={season}>
                    {season}
                  </option>
                );
              })}
            </Select>
          </Col>
          <Hidden xs sm>
            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              style={{
                textAlign: "right",
              }}
            >
              <BuySubscriptionButton
                subscriptionLink={subscriptionLink}
                startCurrentSeasonDate={startCurrentSeasonDate?.value}
                endCurrentSeasonDate={endCurrentSeasonDate?.value}
              />
            </Col>
          </Hidden>
        </Row>
        {this.props.loading ? <Loader /> : this.buildRows()}
      </Page>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    currentSeason: state.items.selectedSeason,
    items: state.items.seasons[state.items.selectedSeason].ids.map(
      (id) => state.items.data[id]
    ),
    availableSeasons: Object.keys(state.items.seasons).sort(
      (a, b) => parseInt(a, 10) < parseInt(b, 10)
    ),
    loading: state.items.seasons[state.items.selectedSeason].fetching,
    subscriptionLink:
      state.items.seasons[state.items.selectedSeason].subscriptionLink ?? null,
    startCurrentSeasonDate:
      state.items.seasons[state.items.selectedSeason].startDate ?? null,
    endCurrentSeasonDate:
      state.items.seasons[state.items.selectedSeason].endDate ?? null,
  };
}

export default connectComponent(Shows, mapStateToProps);
