import React from "react";
import {
  Row,
  Col,
  Hidden,
  ScreenClassRender,
  Container,
  Visible,
} from "react-grid-system";
import { Link } from "react-router-dom";
import { DrawerBottomSheet, Logo, Nav, Icon } from "../components";
import styled from "styled-components";

const FabButton = styled.button`
  position: fixed;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  bottom: 1.5%;
  right: 2%;
  background: white;
  cursor: pointer;
  border: none;
  z-index: 1;
  box-shadow: 0px 0px 33px -4px #000000;
`;

export default class LogoMenuHeader extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
    };
  }

  onFabClick = (e) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        open: true,
      };
    });
  };

  render() {
    const { open } = this.state;
    const { routes, inverted = false } = this.props;
    return (
      <Container>
        <Row align="center" justify="between">
          <Col xs={12} sm={12} md={3} lg={3}>
            <Link to="/" style={{ display: "inline-block" }}>
              <ScreenClassRender
                render={(screenClass) => (
                  <Logo
                    size={
                      ["xs", "sm"].includes(screenClass) ? "small" : "medium"
                    }
                    inverted={inverted}
                  />
                )}
              />
            </Link>
          </Col>

          <Hidden xs sm>
            <Col xs={12} sm={12} md={9} lg={9} style={{ textAlign: "right" }}>
              <Nav routes={routes} inverted={inverted} />
            </Col>
          </Hidden>
          <Visible xs sm>
            <FabButton onClick={this.onFabClick}>
              <Icon
                name="bars"
                style={{ fontSize: "1.5rem", color: "black" }}
              />
            </FabButton>
          </Visible>
          <DrawerBottomSheet
            id="menu-modal"
            open={open}
            onClose={() => this.setState({ open: false })}
          >
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Nav direction="vertical" routes={routes} inverted={true} />
            </div>
          </DrawerBottomSheet>
        </Row>
      </Container>
    );
  }
}
