import React from "react";
import styled from "styled-components";

function Avatar(props) {
  return (
    <div className={props.className}>
      <img src={props.src} alt={props.alt} />
    </div>
  );
}

export default styled(Avatar)`
  border-radius: 100%;
  background: gray;
  & img {
    display: inline;
  }
`;
