import Prismic from 'prismic-javascript';
import PrismicConfig from './prismic-config';

let initialized;
function init() {
  if(initialized) { return initialized; }
  initialized = Prismic.api(PrismicConfig.apiEndpoint, { accessToken: PrismicConfig.accessToken })
    .then(api => ({
        api,
        endpoint: PrismicConfig.apiEndpoint,
        accessToken: PrismicConfig.accessToken,
        linkResolver: PrismicConfig.linkResolver
    }));
  
  return initialized;
}

export function getShowsBy({ season }) {
  return init()
    .then(({ api }) => {
      return api.query(
        [
          Prismic.Predicates.at("document.type", "item"),
          Prismic.Predicates.at("my.item.season", season),
        ],
        {
          orderings: "[my.item.when]",
          fetchLinks: [
            "performer.performer_description",
            "performer.performer_title",
          ],
        }
      );
    })
    .then((response) => response.results);
}

export function getSeasons() {
  return init()
    .then(({ api }) => {
      return api.query(Prismic.Predicates.at("document.type", "_season"), {
        orderings: "[my._season.year desc]",
      });
    })
    .then((response) => response.results);
}

export function getShows() {
  return init()
    .then(({ api }) => {
      return api.query(Prismic.Predicates.at("document.type", "item"), {
        orderings: "[my.item.when]",
        fetchLinks: [
          "performer.performer_description",
          "performer.performer_title",
        ],
      });
    })
    .then((response) => response.results);
}

export function getShow(uid) {
  return init().then(({ api }) => {
    return api.getByUID('item', uid, { fetchLinks: [ 'performer.performer_description', 'performer.performer_title' ] })
  });
}