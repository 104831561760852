import React, { Component, createRef } from "react";
import PropTypes from "prop-types";
import { isIntersecting } from "./isIntersecting";
import styled from "styled-components";
import { imageTransparent } from "./imageTransparent";
// https://developers.google.com/web/fundamentals/performance/lazy-loading-guidance/images-and-video/
// padding-top will be calculate

class Image extends Component {
  static options = {
    root: null,
    threshold: 0.3,
  };

  static propTypes = {
    src: PropTypes.string.isRequired,
    srcSet: PropTypes.string,
    ratio: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    loader: PropTypes.element,
    imageClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    itemProp: PropTypes.string,
    lazy: PropTypes.bool,
  };

  static defaultProps = {
    ratio: null,
    loader: null,
    imageClassName: "",
    containerClassName: "",
    imageStyle: {},
    containerStyle: {},
    itemProp: "image",
    lazy: true,
  };

  constructor(props) {
    super(props);
    this.observer = null;
    this.imageRef = createRef();
    this.containerRef = createRef();
    this.state = {
      src: this.props.src,
      srcSet: this.props.srcSet,
      isLoaded: false,
    };
  }

  onImgLoad = () => {
    // console.log(this.imageRef.current.naturalWidth, this.imageRef.current.naturalHeight);
    // if the img load callback is called because of the transparent src skip it.
    if (this.imageRef.current.src === imageTransparent) return;
    // this.containerRef.current.style.paddingTop = '0px';
    this.setState({ isLoaded: true });
  };

  onImgError = () => {
    this.setState({ isLoaded: false });
  };

  componentDidMount() {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (isIntersecting(entry)) {
          this.loadImage();
          this.observer.unobserve(this.imageRef.current);
        }
      });
    }, Image.options);
    this.observer.observe(this.imageRef.current);
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  loadImage = () => {
    if (this.state.src !== this.imageRef.current.src && !!this.state.src) {
      this.imageRef.current.src = this.state.src;
    }

    if (
      this.state.srcSet !== this.imageRef.current.srcset &&
      !!this.state.srcSet
    ) {
      this.imageRef.current.srcset = this.state.srcSet;
    }
  };

  /** handle new Props */
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { props } = this;
    // console.log({ prevProps }, { prevState }, { state }, { props }, { snapshot });
    let newState = {};
    if (this.state.src !== props.src) {
      newState = Object.assign(newState, { isLoaded: false, src: props.src });
    }

    if (this.state.srcSet !== props.srcSet) {
      newState = Object.assign(newState, {
        isLoaded: false,
        srcSet: props.srcSet,
      });
    }
    if (Object.keys(newState).length !== 0) {
      this.setState(newState, this.loadImage);
    }
  }

  render() {
    let {
      containerStyle,
      width,
      height,
      lazy,
      imageStyle,
      alt,
      onClick,
      loader,
      maxWidth,
    } = this.props;
    let { src, srcSet, isLoaded } = this.state;
    const result = (height / width) * 100;
    containerStyle = { ...containerStyle, paddingTop: `${result}%`, maxWidth };
    return (
      <Container ref={this.containerRef} style={containerStyle}>
        {isLoaded || !lazy ? null : loader}
        <ImageContainer
          ref={this.imageRef}
          loaded={isLoaded || !lazy}
          src={lazy ? imageTransparent : src}
          srcSet={lazy ? null : srcSet}
          onLoad={lazy ? this.onImgLoad : undefined}
          onError={lazy ? this.onImgError : undefined}
          style={imageStyle}
          alt={alt}
          onClick={onClick}
        />
      </Container>
    );
  }
}

const Container = styled.div`
  position: relative;
  display: inline-flex;
  width: 100%;
  height: 0;
  overflow: hidden;
  background: transparent;
  align-items: center;
  justify-content: center;
`;

const ImageContainer = styled.img`
  position: absolute;
  display: inline-block;
  width: 100%;
  opacity: ${(props) => (props.loaded ? "1" : "0")};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 0 none;
  will-change: opacity;
  transition: opacity 100ms ease-in-out;
`;

export default Image;
