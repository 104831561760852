import React from "react";
import { Container } from "react-grid-system";
import { Footer } from "../components";
import LogoMenuHeader from "./LogoMenuHeader";

import routes from "../routes";

function DefaultHeader({ routes }) {
  return (
    <header>
      <LogoMenuHeader routes={routes} inverted={true} />
    </header>
  );
}

const DefaultMainContainer = ({ children, ...rest }) => (
  <Container
    style={{ minHeight: "400px", paddingTop: "15px", paddingBottom: "15px" }}
    {...rest}
  >
    {children}
  </Container>
);

export default function Page({
  children,
  header: Header = DefaultHeader,
  mainContainer: MainContainer = DefaultMainContainer,
}) {
  return (
    <>
      <Header routes={routes} />
      <MainContainer>{children}</MainContainer>
      <Footer />
    </>
  );
}
