import { config, fbAppId, fbClientSecret, fbPageID } from './facebook-config';
const FacebookService = () => {
  const initPromise = new Promise((resolve, reject) => {
    if (window.FB && window.fbAsyncInit) {
      window.FB.init(config);
      window.FB.AppEvents.logPageView();
      resolve(window.FB);
    } else {
      window.fbAsyncInit = function () {
        window.FB.init(config);
        window.FB.AppEvents.logPageView();
        resolve(window.FB);
      }
    }
  });

return {
  makeApiCall(FB, url) {
    return new Promise((resolve) => {
      FB.api(url,
        (response) => {
          if (response && !response.error) {
            resolve(response.data);
          } else {
            resolve(response.error);
          }
        });
    });
  },
  async getPosts() {
    const FB = await initPromise;
    return await this.makeApiCall(FB, `/${fbPageID}/feed?limit=1&fields=permalink_url&access_token=${fbAppId}|${fbClientSecret}`);
    }
  }
};
export default FacebookService();