const routes = [
  { path: "/", label: "home", iconName: "home" },
  {
    path: "/shows",
    label: "spettacoli",
    iconName: "film",
    exact: false,
  },
  // { path: "/extra", label: "eventi extra", iconName: "calendar-plus-o" },
  { path: "/tickets", label: "biglietti", iconName: "ticket" },
  { path: "/about", label: "info", iconName: "info" },
];
export default routes;
