import React, { PureComponent } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

const StyledNavigationUnorderedList = styled.ul`
  display: inline-flex;
  list-style-type: none;
  margin-top: 0;
  margin-right: 0;
  margin-left: 0;
  padding: 0;
  align-items: center;
  flex-direction: ${({ direction }) =>
    direction === "horizontal" ? "row" : "column"};
  & li {
    margin-right: 2px;
  }
  & li:last-of-type {
    margin-right: 0px;
  }
`;

const StyledNavItem = styled.li`
  & .navItem {
    display: block;
    width: 100%;
    text-decoration: none;
    text-transform: capitalize;
    text-align: center;
    padding: 0.8rem;
    border-radius: 25% 10%;
    color: white;
    font-size: 1.2rem;
  }

  .inverted & .navItem {
    color: black;
  }

  & .active,
  & a:hover {
    color: black;
    background: white;
  }

  .inverted & .active,
  .inverted & a:hover {
    color: white;
    background: black;
  }
`;

function NavItem({ route, className }) {
  const { exact = true } = route;
  return (
    <StyledNavItem className={className}>
      <NavLink
        exact={exact}
        to={route.path}
        activeClassName="active"
        className="navItem"
        aria-label={route.label}
      >
        <span className="text">{route.label}</span>
      </NavLink>
    </StyledNavItem>
  );
}

export default class Nav extends PureComponent {
  render() {
    const { routes, direction = "horizontal", inverted } = this.props;
    return (
      <StyledNavigationUnorderedList
        className={`${inverted ? "inverted" : ""}`}
        direction={direction}
      >
        {routes.map((route) => (
          <NavItem route={route} key={route.label} />
        ))}
      </StyledNavigationUnorderedList>
    );
  }
}

