import React, { PureComponent } from "react";
import styled from "styled-components";

const StyledSelect = styled.select`
  & {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 0;
    outline: 0;
    font: inherit;
    /* Personalize */
    height: 2rem;
    padding: 0 4rem 0 1rem;
    background: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg)
        no-repeat right 0.8rem center/1.4rem,
      linear-gradient(to left, rgba(0, 0, 0, 0.85) 3rem, rgba(0, 0, 0, 1) 3rem);
    color: white;
    border-radius: 0.25rem;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }
  & option {
    color: inherit;
    background-color: #320a28;
  }
  &:focus {
    outline: none;
  }
  &::-ms-expand {
    display: none;
  }
`;

const StyledLabel = styled.label`
    margin-right: 0.5rem
    display: inline-block;
`;
export const Label = ({ id, children }) => {
  return <StyledLabel htmlFor={id}>{children}</StyledLabel>;
};

export default class Select extends PureComponent {
  render() {
    const { id, children, label, ...rest } = this.props;
    return (
      <StyledSelect id={id} name={id} {...rest}>
        {children}
      </StyledSelect>
    );
  }
}
