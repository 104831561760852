/**
 * {
 *  selectedSeason: null,
 *  seasons:{
 *      2016:{
 *          id:'',
 *          fetching: false,
 *          fetched: false,
 *          ids: [1, 2, 3]
 *      },
 *      2017:{
 *
 *      },
 *      2018: {
 *
 *      }
 *  },
 *  data:{
 *      '1':{
 *          id: 1,
 *          data
 *      }
 *  }
 * }
 */

export function items(
  state = {
    selectedSeason: null,
    seasons: {
      // year: {
      // id: '',
      // ids: []
      // fetching,
      // fetched,
      //}
    },
    data: {},
  },
  action
) {
  switch (action.type) {
    case "FETCH_AVAILABLE_SEASONS":
      const seasons = action.payload.results.reduce((acc, current) => {
        acc[String(current.data._season.year.value)] = {
          id: current.id,
          fetching: false,
          fetched: false,
          ids: [],
          startDate: current.data._season.start ?? null,
          endDate: current.data._season.end ?? null,
          subscriptionLink:
            current.data._season.twoticketssubscriptionlink ?? null,
        };
        return acc;
      }, {});

      const lastSeason = Math.max(
        ...Object.keys(seasons).map((_) => parseInt(_, 10))
      );
      return Object.assign({}, state, {
        seasons: seasons,
        selectedSeason: String(lastSeason),
      });
    case "FETCH_ITEMS":
      return Object.assign({}, state, {
        selectedSeason: action.payload.currentSeason,
        seasons: {
          ...state.seasons,
          [action.payload.currentSeason]: {
            ...state.seasons[action.payload.currentSeason],
            fetching: true,
          },
        },
      });
    case "FETCH_ITEMS_SUCCESS":
      const newData = action.payload.items.reduce((prev, current) => {
        prev[current.uid] = current;
        return prev;
      }, {});
      return Object.assign({}, state, {
        seasons: {
          ...state.seasons,
          [action.payload.season]: {
            ...state.seasons[action.payload.season],
            fetched: true,
            fetching: false,
            ids: action.payload.items.map((el) => el.uid),
          },
        },
        data: {
          ...state.data,
          ...newData,
        },
      });
    case "FETCH_ITEMS_FAILURE":
      return Object.assign({}, state, {
        loading: false,
        error: action.payload,
      });
    case "FETCH_ITEM_SUCCESS":
      const _ = {
        ...state.data,
        [action.payload.uid]: action.payload.data,
      };
      return Object.assign({}, state, { data: _ });
    case "FETCH_ITEM":
    default:
      return state;
  }
}
