import styled from "styled-components";
import copertina from "./copertina.jpg";

const FullHeightHeader = styled.header`
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(${copertina});
  background-size: cover;
  background-position: center;
  height: 100vh;
  height: 100svh;
  background-attachment: fixed;
  position: relative;
`;

export default FullHeightHeader;
