import React from "react";
import styled from "styled-components";

function _Heading(props) {
  const { as: As, className, children, ...rest } = props;

  return (
    <As className={className} {...rest}>
      {children}
    </As>
  );
}

const Heading = styled(_Heading)`
  font-size: ${({ size }) => {
      if (size === "s") {
        return "1rem";
      } else if (size === "m") {
        return "1.4rem";
      } else if (size === "l") {
        return "1.6em";
      } else if (size === "xl") {
        return "2rem";
      } else if (size === "xxl") {
        return "3rem";
      }
    }}
    ${(props) => props.uppercase && "text-transform: uppercase"};
  text-align: ${({ textAlign }) => textAlign};
  font-weight: 600;
  color: ${(props) => props.color ?? "black"};
`;

Heading.defaultProps = {
  size: "m",
  color: "black",
};

export default Heading;
