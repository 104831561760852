import React from "react";
import { Component } from "react";
import logo from "./logo_2024_bianco.png";
import styled from "styled-components";

class Logo extends Component {
  render() {
    const { className } = this.props;
    return <img className={className} src={logo} alt="Logo Avvistamenti" />;
  }
}

const StyledLogoImg = styled(Logo)`
  width: ${(props) => (props.size === "small" ? "8.5rem" : "10rem")};
  filter: invert(${({ inverted }) => (inverted ? 1 : 0)});
  aspect-ratio: 1 / 1;
`;

export default StyledLogoImg;
