import { createStore, bindActionCreators, applyMiddleware, compose } from 'redux';
import rootReducer from './reducers/index';
import thunk from 'redux-thunk';
import { connect } from 'react-redux';
import * as actionCreators from './actions/index';
const middleware = applyMiddleware(thunk);

function RunDevToolExtensionIfNotInProduction () {
  const shouldExposeState = (!process.env.NODE_ENV || process.env.NODE_ENV !== 'production') &&
                            window.__REDUX_DEVTOOLS_EXTENSION__

  return (shouldExposeState ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f)
}

export const store = createStore(
  rootReducer,
  compose(middleware, RunDevToolExtensionIfNotInProduction())
)

export const mapStateToProps = state => state

function mapDispatchToProps (dispatch) {
  return bindActionCreators(actionCreators, dispatch)
}

export function connectComponent (component, passedMapStateToProps = mapStateToProps) {
  return connect(passedMapStateToProps, mapDispatchToProps)(component)
}