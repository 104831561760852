import React, { Component } from "react";
import styled from "styled-components";
const months = {
  0: "gen",
  1: "feb",
  2: "mar",
  3: "apr",
  4: "mag",
  5: "giu",
  6: "lug",
  7: "ago",
  8: "set",
  9: "ott",
  10: "nov",
  11: "dic",
};
const CardContainer = styled.div`
  position: relative;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  margin: 3% 0%;
  color: white;
  border-top-right-radius: 10%;
  border-bottom-left-radius: 10%;
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 70%,
      rgba(0, 0, 0, 1) 100%
    ); /* w3c */
  }
`;

const DateContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.25);
  padding: 2.5% 5%;
  border-radius: 0px 0px 0px 25px;
  backdrop-filter: blur(40px);

  & span {
    display: block;
    text-align: center;
  }
  & span.day {
    font-size: 2rem;
  }
`;

const Circle = styled.div`
  position: absolute;
  right: 5%;
  bottom: 10%;
`;

const CategoryContainer = styled.div`
  position: absolute;
  top: 2.5%;
  left: 2%;
`;

class Card extends Component {
  render() {
    const date = new Date(this.props.date);
    return (
      <CardContainer>
        {this.props.img}
        <DateContainer>
          <span className="day">{date.getDate()}</span>
          <span>{months[date.getMonth()]}</span>
        </DateContainer>
        <CategoryContainer>{this.props.category}</CategoryContainer>
        {this.props.adult === 2 ? (
          <Circle>
            <em>Consigliato ad un pubblico adulto</em>
          </Circle>
        ) : null}
      </CardContainer>
    );
  }
}

Card.defaultProps = {
  title: "Title",
  date: "1970-08-04",
  type: "theater", // music|extra|talk
};

export default Card;
