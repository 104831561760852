import React from "react";
import { Show } from "../../components";
import { DoItYourSelfBuyButton } from "../TwoTicketsButton";
import { CheckDate } from "../../utils/dateUtils";
export const BuySubscriptionButton = ({
  subscriptionLink,
  startCurrentSeasonDate,
  endCurrentSeasonDate,
}) => {
  return (
    <Show
      when={
        subscriptionLink != null &&
        startCurrentSeasonDate != null &&
        endCurrentSeasonDate != null &&
        CheckDate(new Date()).isAfter(new Date(startCurrentSeasonDate)) &&
        CheckDate(new Date()).isBefore(new Date(endCurrentSeasonDate))
      }
    >
      <DoItYourSelfBuyButton
        href={subscriptionLink?.value.url}
        target={subscriptionLink?.value.target}
      >
        Compra Abbonamento
      </DoItYourSelfBuyButton>
    </Show>
  );
};
