import React, { Component } from "react";
import { Row, Col } from "react-grid-system";

import Page from "./Page";

import { Image, Heading } from "../components";

import { googleMapApiKey } from "./constants";

import ComuneLogo from "./images/ricadi-logo.gif";
import DeminimiLogo from "./images/deminimi.png";
import LogoProLoco from "./images/LogoProLoco.jpg";
import VillaPaola from "./images/villapaola.png";
import styled from "styled-components";

const LinkLogo = styled.a`
  text-align: center;
  font-size: 0.8rem;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &:link,
  &:visited {
    color: black;
  }
  padding: 1rem 0rem;
`;

export default class About extends Component {
  render() {
    const maxWidth = Math.floor(window.innerWidth / 10) * 10;
    const staticGoogleMapImg = `https://maps.googleapis.com/maps/api/staticmap?center=Teatro+torre+marrana&zoom=13&scale=1&size=${maxWidth}x300&maptype=roadmap&key=${googleMapApiKey}&format=png&visual_refresh=true&markers=size:mid%7Ccolor:0x00ff80%7Clabel:%7CTeatro+torre+marrana&markers=size:mid%7Ccolor:0xff0000%7Clabel:1%7CTeatro+torre+marrana`;
    return (
      <Page>
        <Row style={{ marginBottom: "25px" }}>
          <Col xs={12} sm={6} md={6} lg={6} style={{ marginBottom: "25px" }}>
            <Heading as="h2" style={{ marginBottom: "25px" }}>
              Chi siamo
            </Heading>
            <p>
              Avvistamenti è un festival di teatro, musica, degustazioni, eventi
              giunto all'ottava edizione. Il progetto è ideato e organizzato
              dall'Associazione Culturale Avvistamenti.
            </p>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Heading as="h2" style={{ marginBottom: "25px" }}>
              Contatti
            </Heading>
            <p>
              Email:{" "}
              <a className="underlined" href="mailto:avvistamenti@hotmail.com">
                avvistamenti@hotmail.com
              </a>
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={6} md={6} lg={6}>
            <Heading as="h2" style={{ marginBottom: "25px" }}>
              Come raggiungere il Teatro Torre Marrana
            </Heading>
            <p style={{ marginBottom: "25px" }}>
              Per impostare il navigatore, chiedere indicazioni per{" "}
              <strong>"Teatro Torre Marrana"</strong> o clicca direttamente
              sulla mappa.
            </p>
          </Col>
          <Col xs={12} sm={6} md={6} lg={6}>
            <a
              href="https://www.google.com/maps/dir/Teatro+torre+marrana/"
              style={{ position: "relative" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i
                style={{
                  position: "absolute",
                  margin: "5px",
                  fontSize: "large",
                }}
                className="fa fa-external-link"
                aria-hidden="true"
              ></i>
              <Image
                width={540}
                height={240}
                src={staticGoogleMapImg}
                alt="Google Map of Teatro torre marrana"
              />
            </a>
          </Col>
        </Row>
        <Row align="center" justify="center" style={{ marginTop: "2rem" }}>
          <Col xs={12} md={2} lg={2}>
            <LinkLogo
              target="_blank"
              href="https://www.comune.ricadi.vv.it/"
              rel="noopener noreferrer"
            >
              <div style={{ marginBottom: "1rem", textAlign: "center" }}>
                Con il patrocinio e il contributo di
              </div>
              <img
                style={{ objectFit: "contain", width: "100%" }}
                width={150}
                height={75}
                loading="lazy"
                src={ComuneLogo}
                alt="Con il patrocinio e il contributo del comune di Ricadi (VV)"
              />
              <div>Comune di Ricadi</div>
            </LinkLogo>
          </Col>
          <Col xs={12} md={2} lg={2}>
            <LinkLogo
              target="_blank"
              href="https://www.prolococapovaticano.com/"
              rel="noopener noreferrer"
            >
              <div style={{ marginBottom: "1rem", textAlign: "center" }}>
                Con la collaborazione di
              </div>
              <img
                style={{
                  objectFit: "contain",
                  width: "100%",
                  maxWidth: "200px",
                }}
                width={150}
                height={75}
                loading="lazy"
                src={LogoProLoco}
                alt="Con la collaborazione di Pro Loco Capo Vaticano"
              />
              <div style={{ marginBottom: "1rem", textAlign: "center" }}>
                Pro loco Capo Vaticano
              </div>
            </LinkLogo>
          </Col>
          <Col xs={12} md={2} lg={2}>
            <LinkLogo
              target="_blank"
              href="https://deminimi.com/"
              rel="noopener noreferrer"
            >
              <div style={{ marginBottom: "1rem", textAlign: "center" }}>
                Sponsor
              </div>
              <img
                style={{
                  objectFit: "contain",
                  width: "100%",
                  filter: "invert(1)",
                  maxWidth: "200px",
                }}
                width={150}
                height={75}
                loading="lazy"
                src={DeminimiLogo}
                alt="Sponsor di avvistamenti. Ristorante De Minimi"
              />
              <div>Ristorante De' Minimi</div>
            </LinkLogo>
          </Col>
          <Col xs={12} md={2} lg={2}>
            <LinkLogo
              target="_blank"
              href="https://www.villapaolatropea.it/"
              rel="noopener noreferrer"
            >
              <div style={{ marginBottom: "1rem", textAlign: "center" }}>
                Sponsor
              </div>
              <img
                style={{
                  objectFit: "contain",
                  width: "100%",
                  maxWidth: "200px",
                }}
                width={150}
                height={75}
                loading="lazy"
                src={VillaPaola}
                alt="Sponsor di Avvistamenti"
              />
            </LinkLogo>
          </Col>
        </Row>
      </Page>
    );
  }
}
