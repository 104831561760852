export function zoom(state = {
  loading: true,
  error: null,
  item: {
    data:{}
  }
}, action) {
switch (action.type) {
    default:
      return state;
  }
}