import styled from "styled-components";

const StyledChip = styled.div`
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  border-radius: 1rem;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: default;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  vertical-align: middle;
  box-sizing: border-box;
  font-size: 1.2rem;
  color: ${(props) => props.color ?? "black"};
  padding: 0.438rem 0.313rem;
  font-weight: normal;
  background-color: ${(props) => props.bgColor ?? "lightgrey"};
  min-width: 5.5rem;
  text-transform: uppercase;
`;

export default StyledChip;
