import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Loader, Icon, Card, Image, Heading, Show } from "../components";
import { Row, Col, ScreenClassRender } from "react-grid-system";
import { createMarkup } from "../utils";
import css from "./zoom.module.css";
import Page from "./Page";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { connectComponent } from "../store";

import { DoItYourSelfBuyButton } from "./TwoTicketsButton";

import get from "lodash.get";
import { CheckDate, addHours, format } from "../utils/dateUtils";

const mapPrismic = {
  paragraph: "p",
  "list-item": "li",
  strong: "strong",
  em: "em",
};

const CenterVerticalSpaced = ({ children }) => {
  return (
    <ScreenClassRender
      render={(screenClass) => (
        <div
          style={{
            textAlign: ["xs", "sm"].includes(screenClass) ? "center" : "left",
            margin: "1rem 0rem",
          }}
        >
          {children}
        </div>
      )}
    />
  );
};

/**
 * create an html string to be set as content
 * @param { {type: string; text: string; spans: { start: number; end: number; type: string }[]}[] } elements
 */
const RichText = (elements) => {
  const finalFormattedString = [];
  for (const element of elements) {
    const splitted = element.text.split("");
    finalFormattedString.push(`<${mapPrismic[element.type]}>`);
    let insertions = 0;
    for (let i = 0; i < element.spans.length; i++) {
      const span = element.spans[i];
      splitted.splice(span.start + insertions, 0, `<${mapPrismic[span.type]}>`);
      splitted.splice(
        span.end + 1 + insertions,
        0,
        `</${mapPrismic[span.type]}>`
      );
      insertions += 2;
    }
    finalFormattedString.push(splitted.join(""));
    finalFormattedString.push(`</${mapPrismic[element.type]}>`);
  }

  return finalFormattedString.join("");
};

function getDataFromItem(data, currentWidth = window.innerWidth) {
  if (!data) return {};
  const view = currentWidth <= 768 ? "mobile" : "tablet";
  const content = get(data, "item.description.value[0].text", "");
  const allContent = get(data, "item.description.value", []);
  const title = get(data, "item.title.value[0].text", "");
  const date = new Date(get(data, "item.when.value", "1970-01-01"));
  let imageGallery = get(data, "item.image-gallery.value[0].image.value.views");
  const imgSrc = get(
    imageGallery,
    `${view}.url`,
    "http://via.placeholder.com/480x300"
  );
  const dimensions = get(imageGallery, `${view}.dimensions`, {
    width: 480,
    height: 300,
  });
  const credits = get(data, "item.credits.value", []);
  const awards = get(data, "item.awards.value", []);
  const performer = get(data, "item.performer.value.document.data.performer", {
    performer_title: [{ text: "" }],
    performer_description: [{ text: "" }],
  });
  const adult = get(data, "item.adult.value", 0);
  const twoTicketsLink = get(data, "item.2tickets_link.value");
  return {
    content,
    allContent,
    title,
    date,
    imgSrc,
    dimensions,
    credits,
    awards,
    performer,
    adult,
    twoTicketsLink,
  };
}

function Awards(props) {
  if (props.data.length > 0 && props.data[0].text !== "") {
    return (
      <div className={css.awardsContainer}>
        <Heading as="h4" size="s">
          Premi:
        </Heading>
        <ul className={css.awards}>
          {props.data.map((e, i) => {
            return (
              <li key={`awards${i}`}>
                <Icon name="trophy" style={{ marginRight: "5px" }} />
                {e.text.trim()}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
  return null;
}

class Zoom extends Component {
  constructor() {
    super(...arguments);
    this.renderPage = this.renderPage.bind(this);
    this.state = {
      loading: true,
      item: this.props.item ? this.props.item.data : null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.item) {
      this.setState({ item: nextProps.item, loading: false });
    }
  }

  componentDidMount() {
    if (this.state.item) {
      this.setState({ loading: false });
    } else {
      const { uid } = this.props.match.params;
      this.props.getShow(uid);
    }
  }

  renderPage() {
    const {
      title,
      date,
      content,
      allContent,
      imgSrc,
      dimensions,
      credits,
      awards,
      performer,
      adult,
      twoTicketsLink,
    } = getDataFromItem(this.state.item);
    this.startDate = addHours(date, 2);
    const addToCalendarProps = {
      startDate: format(this.startDate),
      endDate: format(addHours(this.startDate, 2)),
      startTime: format(this.startDate, "hh:mm"),
      endTime: format(addHours(this.startDate, 2), "hh:mm"),
    };

    return (
      <Page>
        <Helmet>
          <meta property="og:locale" content="it_IT" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={title} />
          <meta property="og:description" content={content} />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:image" content={imgSrc} />
          <meta property="og:site_name" content="Avvistamenti Teatrali" />

          <meta name="twitter:card" content="summary" />
          <meta name="twitter:description" content={content} />
          <meta name="twitter:title" content={title} />
        </Helmet>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6}>
            <Heading as="h1" size="l">
              {title}
            </Heading>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6}>
            <Card
              date={date}
              img={
                <Image
                  src={imgSrc}
                  width={dimensions.width}
                  height={dimensions.height}
                  alt={title}
                />
              }
              adult={adult}
            />
          </Col>
          <Col xs={12} sm={12} md={12} lg={6}>
            <ul
              className={css.credits}
              dangerouslySetInnerHTML={createMarkup(RichText(credits))}
            ></ul>
            <Awards data={awards} />
            <Show
              when={
                twoTicketsLink != null &&
                CheckDate(new Date()).isBefore(new Date(date))
              }
            >
              <CenterVerticalSpaced>
                <DoItYourSelfBuyButton
                  href={twoTicketsLink?.url}
                  target={twoTicketsLink?.target}
                  size="normal"
                >
                  Compra biglietto
                </DoItYourSelfBuyButton>
                <br />
                <a
                  className="underlined"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://wa.me/39060406?text=${encodeURIComponent(
                    `Buongiorno vorrei comprare 2 biglietti per l'evento "${title}" di Avvistamenti al Teatro Torre Marrana...`
                  )}`}
                >
                  Compra su Whatsapp DIY
                  <i
                    style={{ marginLeft: "5px" }}
                    className="fa fa-whatsapp"
                    aria-hidden="true"
                  ></i>
                </a>
              </CenterVerticalSpaced>
            </Show>
            <div
              style={{ marginTop: "20px" }}
              dangerouslySetInnerHTML={createMarkup(RichText(allContent))}
            ></div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} style={{ marginTop: "20px" }}>
            <Heading as="h3" size="m">
              {performer.performer_title[0].text}
            </Heading>
            <p style={{ marginTop: "20px" }}>
              {performer.performer_description[0].text}
            </p>
          </Col>
        </Row>
        {/** If the day of the show has yet to come */}
        <Row justify="center" align="center">
          <Col xs={12} sm={10} md={6} lg={6} style={{ textAlign: "center" }}>
            <Show when={CheckDate(new Date()).isBefore(new Date(date))}>
              <AddToCalendarButton
                buttonStyle="default"
                inline
                size="5|4|3"
                label="Aggiungi al calendario!"
                name={`${title} | Avvistamenti Teatrali`}
                options={["Apple", "Google", "iCal", "Yahoo", "Outlook.com"]}
                location="Teatro Torre Marrana, Ricadi, Vibo Valentia"
                timeZone="currentBrowser"
                description={`${content} ${
                  twoTicketsLink?.url
                    ? `Biglietti in vendita su ${twoTicketsLink?.url}`
                    : ""
                }`}
                {...addToCalendarProps}
              />
            </Show>
          </Col>
        </Row>
      </Page>
    );
  }

  render() {
    return this.state.loading ? <Loader /> : this.renderPage();
  }
}

function mapStateToProps(state, ownProps) {
  const item = state.items.data[ownProps.match.params.uid];
  return {
    item,
  };
}

export default connectComponent(Zoom, mapStateToProps);
