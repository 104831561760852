import React, { Component } from "react";
import { Container } from "react-grid-system";
import { withRouter } from "react-router-dom";

import { Heading, Button } from "../components";
import FullHeightHeader from "../components/Header/FullHeightHeader";
import LogoMenuHeader from "./LogoMenuHeader";
import Page from "./Page";

class Home extends Component {
  render() {
    return (
      <Page
        header={({ routes }) => {
          return (
            <FullHeightHeader>
              <LogoMenuHeader routes={routes} />

              <Container>
                <div style={{ marginTop: "6rem" }}>
                  <Heading as="h1" size="l" color="white">
                    Dal 2 al 23 <br />
                    Agosto 2024!
                  </Heading>
                  <div style={{ marginTop: "1rem" }}>
                    <Button
                      as="a"
                      href="/shows"
                      size={"xl"}
                      variant="secondary"
                    >
                      Scopri gli eventi!
                    </Button>
                  </div>
                </div>
              </Container>
            </FullHeightHeader>
          );
        }}
        mainContainer={() => null}
      />
    );
  }
}

export default withRouter(Home);