import React from "react";
import styled, { keyframes } from "styled-components";
import dialogPolyfill from "dialog-polyfill";

const waitTransition = (el) => {
  return new Promise((resolve) => {
    el.addEventListener("animationend", resolve, { once: true });
  });
};

const slideUp = keyframes`
  from {
    transform: translateY(0%);
  }
  to {
    transform: translateY(-100%);
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
`;

const fadeInWithBlur = keyframes`
    from {
      opacity: 0;
      backdrop-filter: blur(0px);
    }
    to {
      opacity: 1;
      backdrop-filter: blur(3px);
    }
`;

const fadeOutWithBlur = keyframes`
    from {
      opacity: 1;
      backdrop-filter: blur(3px);
    }
    to {
      opacity: 0;
      backdrop-filter: blur(0px);
    }
`;

const StyledDrawer = styled.dialog`
  padding: 0;
  border: none !important;
  position: fixed;
  display: block;
  top: 100%;
  z-index: 2;
  transform: translateY(0%);

  &::backdrop,
  & + .backdrop {
    -webkit-tap-highlight-color: transparent;
    background: rgba(0, 0, 0, 0.85);
  }

  & + .backdrop {
    position: fixed;
    inset: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100dvh;
    background: rgba(0, 0, 0, 0.85);
  }

  &[open]::backdrop,
  &[open] + .backdrop {
    animation: ${fadeInWithBlur} 0.3s ease forwards;
  }

  &.hide::backdrop,
  &.hide + .backdrop {
    animation: ${fadeOutWithBlur} 0.3s ease forwards;
  }

  background-color: white;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  width: 100%;
  max-width: 100%;
  max-height: 90dvh;
  display: block;
  padding: 1rem;

  &[open] {
    animation: ${slideUp} 0.3s forwards;
  }
  &.hide {
    animation: ${slideDown} 0.3s forwards;
  }
`;

export default class DrawerBottomSheet extends React.Component {
  componentDidUpdate(prevProps, prevState) {
    if (this.props.open !== prevProps.open) {
      if (this.props.open) {
        this.open();
      } else {
        this.close();
      }
    }
  }

  open = () => {
    this.ref.showModal();
  };

  close = async () => {
    this.ref.close();
  };

  onClick = async (e) => {
    const rect = e.target.getBoundingClientRect();

    this.clickedInDialog =
      rect.top <= e.clientY &&
      e.clientY <= rect.top + rect.height &&
      rect.left <= e.clientX &&
      e.clientX <= rect.left + rect.width;
    if (!this.clickedInDialog) {
      this.ref.classList.add("hide");
      await waitTransition(this.ref);
      this.ref.classList.remove("hide");
      this.props.onClose();
    }
  };

  componentDidMount() {
    // attach listener
    if (!this.registered) {
      dialogPolyfill.registerDialog(this.ref);
      this.registered = true;
    }

    this.ref.addEventListener("click", this.onClick);
  }

  componentWillUnmount() {
    // detach listener
    this.ref.removeEventListener("click", this.onClick);
  }

  render() {
    const { children } = this.props;
    return (
      <StyledDrawer id="modal" ref={(ref) => (this.ref = ref)}>
        {children}
      </StyledDrawer>
    );
  }
}
