import React from "react";
import styled from "styled-components";
import { Icon } from "..";
import { facebookPageHref } from "../../services/facebook-config";
import { Container, Row, Col } from "react-grid-system";

const SocialIconLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  font-size: 22px;
  border-radius: 40px;
  text-decoration: none;
`;

const instagramLink = "https://www.instagram.com/avvistamenti.fest";
const FacebookLink = styled(SocialIconLink)`
  color: white;  
  background: #4267b2;
`;

const InstagramLink = styled(SocialIconLink)`  
  color: #fff;  
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  box-shadow: 0px 3px 10px rgba(0,0,0,.25);
`;

const LinksList = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  & > a {
    margin-right: 5px;
  }
  & > a:last-child {
    margin-right: 0px;
  }
`;

const Footer = (props) => {
  return (
    <footer>
      <Container>
        <Row>
          <Col xs={12} sm={12} lg={12}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={12}
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                <LinksList>
                  <FacebookLink
                    href={facebookPageHref}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Vai alla pagina Facebook"
                  >
                    <Icon name="facebook-official" aria-hidden="true" />
                  </FacebookLink>

                  <InstagramLink
                    href={instagramLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Vai alla pagina Instagram"
                  >
                    <Icon name="instagram" aria-hidden="true" />
                  </InstagramLink>
                </LinksList>
              </Col>
              <Col
                xs={12}
                sm={12}
                lg={12}
                style={{ paddingTop: "10px", paddingBottom: "10px" }}
              >
                <div style={{ textAlign: "center", fontSize: "0.5rem" }}>
                  @copyright Avvistamenti Teatrali 2016 -{" "}
                  {new Date().getFullYear()}
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} lg={12}>
            <Row>
              <Col
                xs={12}
                sm={12}
                lg={12}
                style={{
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  textAlign: "center",
                }}
              >
                <div style={{ textAlign: "center", fontSize: "0.5rem" }}>
                  <a
                    className="underlined"
                    target="_blank"
                    href="https://2d4cf20b.sibforms.com/serve/MUIFAP_CA0lz_GLECo0w49_JyVm9gO3qG2uz22Go5TYWZ04Q2aK5jtR5mEcMOEEqJXqL8moGssnqbCsVoCAVFBIX6h_FvIpsNHuoKdNEulvQ78z1qEdUGACGO-GZYJ2yUqy7dqWbMb63_KxYbK7lUOdUq9JmsKbo1LKhlyOeaV94FY0jEMXtFRBAgVrcAEyg1DYqpco1A_hURv3q"
                    rel="noopener noreferrer"
                  >
                    Iscriviti alla nostra newsletter per ricevere sconti
                    esclusivi!
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
