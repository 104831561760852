import styled from "styled-components";

const sizes = {
  normal: "1rem",
  xl: "1.8rem",
};

const Button = styled.a`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;

  background: transparent;

  color: ${(props) => (props.variant === "secondary" ? "white" : "black")};
  border: 1px solid
    ${(props) => (props.variant === "secondary" ? "white" : "black")};

  font: inherit;
  font-size: ${({ size }) => sizes[size] ?? sizes.normal};
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  background: transparent;
  padding: 10px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 200px;
  cursor: pointer;
  transition: background 0.3s ease;

  text-decoration: none;

  &:hover {
    opacity: 0.85;
  }
`;

export default Button;
