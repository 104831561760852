import React, { Component } from "react";
import styled, { css } from "styled-components";

const FilterButton = styled.button`
  color: black;
  background: white;
  padding: 10px;
  margin-right: 5px;
  border-radius: 5px;
  border: 0;
  cursor: pointer;
  ${(props) =>
    props.active
    ? css`
        color: white;
        background: black;
        padding: 10px;
        margin-right: 5px;
        border-radius: 5px;
      `
    : ""}}
`;

const FilterBarContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > ${FilterButton}:last-child {
    margin-right: 0px;
  }
`;

class FilterBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeFilter: this.props.atStart,
    };
  }

  componentDidMount() {
    this.props.onChange(this.props.atStart);
  }

  onFilterSelection = ({ target: { dataset } }) => {
    const selectedFilter = dataset.filter;
    this.setState({ activeFilter: selectedFilter });
    this.props.onChange(selectedFilter);
  };

  render() {
    return (
      <FilterBarContainer>
        {this.props.filters.map((item) => (
          <FilterButton
            key={`${item}`}
            active={this.state.activeFilter === item}
            data-filter={item}
            onClick={this.onFilterSelection}
          >
            {item}
          </FilterButton>
        ))}
      </FilterBarContainer>
    );
  }
}

export default FilterBar;
