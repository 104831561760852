/**
 *
 * @param {date} inputDate
 * @returns
 */
export function CheckDate(inputDate) {
  const today = new Date();
  return {
    /**
     *
     * @returns {boolean}
     */
    isToday() {
      return (
        inputDate.getDate() === today.getDate() &&
        inputDate.getMonth() === today.getMonth() &&
        inputDate.getFullYear() === today.getFullYear()
      );
    },
    /**
     *
     * @param {Date} otherDate
     * @returns {boolean}
     */
    isAfter(otherDate) {
      return inputDate > otherDate;
    },
    /**
     *
     * @param {date} otherDate
     * @returns {boolean}
     */
    isBefore(otherDate) {
      return inputDate < otherDate;
    },
  };
}

/**
 * 
 * @param {Date} date 
 * @returns {string}
 */
export function format(date, template = "yyyy-mm-dd") {
  if (template === "hh:mm") {
    return `${String(date.getUTCHours()).padStart(2, 0)}:${String(
      date.getUTCMinutes()
    ).padStart(2, 0)}`;
  }
  const mm = String(date.getUTCMonth() + 1).padStart(2, "0");
  const dd = String(date.getUTCDate()).padStart(2, "0");

  return `${date.getUTCFullYear()}-${mm}-${dd}`;
}

/**
 * returns a copy + n hours
 * @param {Date} date
 * @param {number} hours
 * @returns {Date}
 */
export function addHours(date, hours) {
  const copy = new Date(date);
  copy.setUTCHours(copy.getUTCHours() + hours);
  return copy;
}

/**
 * returns a copy + n hours
 * @param {Date} date
 * @param {number} days
 * @returns {Date}
 */
export function addDays(date, days) {
  const copy = new Date(date);
  copy.setUTCHours(copy.setUTCDate(date.getUTCDate() + days));
  return copy;
}