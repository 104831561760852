import React, { Component } from "react";
import GlobalStyle from "./globalStyles";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Provider } from "react-redux";
import { store } from "./store";
import * as actions from "./actions";

import { Home, About, Shows, Tickets, Zoom } from "./pages";

export default class App extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      loading: true,
    };
  }

  componentDidMount() {
    store.dispatch(actions.getSeasons()).then(() => {
      this.setState({ loading: false });
    });
  }

  render() {
    return this.state.loading ? null : this.renderSite();
  }

  renderSite() {
    return (
      <Provider store={store}>
        <Router>
          <Route exact path="/" component={Home} />
          <Switch>
            <Route exact path="/shows" component={Shows} />
            <Route path="/shows/:uid" component={Zoom} />
          </Switch>
          <Route exact path="/tickets" component={Tickets} />
          <Route exact path="/about" component={About} />
          <GlobalStyle />
        </Router>
      </Provider>
    );
  }
}
