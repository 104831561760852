export function facebook_posts(state = {
  hasLoaded: false,
  loading: true,
  error: null,
  posts: []
}, action) {
  switch (action.type) {
    case 'FETCH_FB_POSTS':
      return Object.assign({}, state, { loading: true });
    case 'FETCH_FB_POSTS_SUCCESS':
      return Object.assign({}, state, { loading: false, hasLoaded: true, posts: action.payload });
    case 'FETCH_FB_POSTS_FAILURE':
      return Object.assign({}, state, { loading: false, hasLoaded: true, error: action.payload });
    default:
      return state;
  }
}