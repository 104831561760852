import * as PrismicService from "../services/prismic-service";
import FacebookService from "../services/facebook-service";

export function getShows() {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_ITEMS" });
    return PrismicService.getShows()
      .then((results) => {
        let processed = results.reduce((accumulator, current) => {
          accumulator[current.uid] = current;
          return accumulator;
        }, {});

        dispatch({
          type: "FETCH_ITEMS_SUCCESS",
          payload: { posts: results, postsUID: processed },
        });
      })
      .catch((reason) => {
        dispatch({ type: "FETCH_ITEMS_FAILURE" });
        console.warn("error retrieve shows", reason);
      });
  };
}

export function getShowsBy({ season }) {
  return (dispatch, getState) => {
    const { seasons } = getState().items;
    const id = seasons[season].id;
    dispatch({ type: "FETCH_ITEMS", payload: { currentSeason: season } });
    return PrismicService.getShowsBy({ season: id })
      .then((items) => {
        dispatch({
          type: "FETCH_ITEMS_SUCCESS",
          payload: { items, season },
        });
      })
      .catch((reason) => {
        dispatch({ type: "FETCH_ITEMS_FAILURE" });
        console.warn("error retrieve shows", reason);
      });
  };
}

export function getSeasons() {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_SEASONS" });
    return PrismicService.getSeasons()
      .then((results) => {
        dispatch({
          type: "FETCH_AVAILABLE_SEASONS",
          payload: { results },
        });
      })
      .catch((reason) => {
        dispatch({ type: "FETCH_AVAILABLE_SEASONS_FAIL" });
        console.warn("error retrieve shows", reason);
      });
  };
}

export function getShow(uid) {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_ITEM" });
    return PrismicService.getShow(uid)
      .then((result) => {
        dispatch({ type: "FETCH_ITEM_SUCCESS", payload: result });
      })
      .catch((reason) => {
        dispatch({ type: "FETCH_ITEM_FAILURE", payload: reason });
      });
  };
}

export function getLastFacebookPosts() {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_FB_POSTS" });
    const { posts } = getState().facebook_posts;
    if (posts.length === 0) {
      return FacebookService.getPosts()
        .then((response) => {
          dispatch({ type: "FETCH_FB_POSTS_SUCCESS", payload: response });
        })
        .catch((error) => {
          dispatch({ type: "FETCH_FB_POSTS_FAILURE", payload: error });
        });
    } else {
      dispatch({ type: "FETCH_FB_POSTS_SUCCESS", payload: posts });
      return Promise.resolve();
    }
  };
}
